import { defineStore } from "pinia";
import historyImages from "@/data/historyImages.json";
import { type histaryList, type histaryItem } from "@/type/images.d";
export default defineStore("historyImages", {
  state: () => {
    return {
      historyImages: [] as histaryList,
    };
  },
  actions: {
    async getData() {
      this.historyImages = historyImages.data as histaryList;
    },
    async getItem(item: histaryItem | histaryList) {
      if (Array.isArray(item)) {
        item.forEach((i) => {
          const index = this.idAdd(i.id);
          if (index == -1) {
            this.historyImages.unshift(i);
          }
        });
        return;
      }
      const index = this.idAdd(item.id);
      if (index == -1) {
        this.historyImages.unshift(item);
      }
    },
    idAdd(id: string) {
      return this.historyImages.findIndex((j) => j.id === id);
    },
  },
});
