import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted } from "vue";
import useInfo from "@/store/module/useInfo";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import useHistoryImages from "@/store/module/useHistoryImages";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

onMounted(() => {
  const { getData: getUseInfo } = useInfo();
  const { getData: getHistoryImages } = useHistoryImages();
  getUseInfo();
  getHistoryImages();
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!

  return (_openBlock(), _createBlock(_component_el_config_provider, { locale: _unref(zhCn) }, {
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})