<template>
  <el-aside class="h-100">
    <div class="aside-top" @click="handleMenu('inspiration')">
      <img src="@/assets/logo.png" alt="" />
    </div>
    <div class="aside-center flex flex-column center">
      <span
        class="menu flex flex-column center"
        v-for="(item, index) in menuList"
        :key="index"
        :class="{ active: activeMenu === item.value }"
        @click="handleMenu(item.value)"
        ><i
          :class="[activeMenu === item.value ? 'icon-color-white' : 'color-585D71', 'icon-size-medium', item.icon]"
        ></i>
        <span class="color-585D71">{{ item.name }}</span>
      </span>
    </div>
    <div class="aside-footer" @click="handleMenu('history')">
      <el-avatar :size="70" :src="useInfo.avatar" fit="cover" />
    </div>
  </el-aside>
</template>
<script setup lang="ts">
import { ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import getUseInfo from "@/store/module/useInfo";
import { storeToRefs } from "pinia";
const { useInfo } = storeToRefs(getUseInfo());
const menuList = [
  {
    name: "灵感",
    value: "inspiration",
    icon: "icon-a-11",
    list: ["inspiration", "image-details"],
  },
  {
    name: "装修",
    value: "fitment",
    icon: "icon-a-2",
    list: ["fitment", "image-edit"],
  },
  {
    name: "历史",
    value: "history",
    icon: "icon-a-3",
    list: ["history"],
  },
  {
    name: "购物车",
    value: "shopping_cart",
    icon: "icon-a-41",
    list: ["shopping_cart", "submit_order"],
  },
  {
    name: "订单",
    value: "order",
    icon: "icon-a-5",
    list: ["order", "booking"],
  },
];
const router = useRouter();
const route = useRoute();
const activeMenu = ref("");
const handleMenu = (value: string) => {
  activeMenu.value = value;
  router.push({
    name: value,
  });
};
watch(
  () => route.name,
  (name: any) => {
    activeMenu.value = menuList.find((i) => i.list.includes(name))?.value || "inspiration";
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>
<style lang="scss" scoped>
.el-aside {
  padding: 16px 0;
  background-color: var(--el-aside-bg);
  .aside-top {
    height: 60px;
    img {
      width: calc(100% - 30px);
      cursor: pointer;
    }
  }
  .aside-footer {
    height: 60px;
    .el-avatar {
      cursor: pointer;
    }
  }
  .aside-center {
    height: calc(100% - (60px + 16px) * 2);
    .menu {
      width: 62px;
      height: 62px;
      font-size: 14px;
      font-family: MiSans-Regular;
      cursor: pointer;
      span {
        height: 22px;
      }
      &.active {
        border-radius: 12px;
        background: var(--el-aside-menu-active);
        span {
          color: #fff;
        }
      }
    }
  }
}
</style>
