import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "aside-center flex flex-column center" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "color-585D71" }

import { ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import getUseInfo from "@/store/module/useInfo";
import { storeToRefs } from "pinia";

export default /*@__PURE__*/_defineComponent({
  __name: 'aside',
  setup(__props) {

const { useInfo } = storeToRefs(getUseInfo());
const menuList = [
  {
    name: "灵感",
    value: "inspiration",
    icon: "icon-a-11",
    list: ["inspiration", "image-details"],
  },
  {
    name: "装修",
    value: "fitment",
    icon: "icon-a-2",
    list: ["fitment", "image-edit"],
  },
  {
    name: "历史",
    value: "history",
    icon: "icon-a-3",
    list: ["history"],
  },
  {
    name: "购物车",
    value: "shopping_cart",
    icon: "icon-a-41",
    list: ["shopping_cart", "submit_order"],
  },
  {
    name: "订单",
    value: "order",
    icon: "icon-a-5",
    list: ["order", "booking"],
  },
];
const router = useRouter();
const route = useRoute();
const activeMenu = ref("");
const handleMenu = (value: string) => {
  activeMenu.value = value;
  router.push({
    name: value,
  });
};
watch(
  () => route.name,
  (name: any) => {
    activeMenu.value = menuList.find((i) => i.list.includes(name))?.value || "inspiration";
  },
  {
    deep: true,
    immediate: true,
  }
);

return (_ctx: any,_cache: any) => {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_aside = _resolveComponent("el-aside")!

  return (_openBlock(), _createBlock(_component_el_aside, { class: "h-100" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "aside-top",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (handleMenu('inspiration')))
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)
      ])),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(menuList, (item, index) => {
          return _createElementVNode("span", {
            class: _normalizeClass(["menu flex flex-column center", { active: activeMenu.value === item.value }]),
            key: index,
            onClick: ($event: any) => (handleMenu(item.value))
          }, [
            _createElementVNode("i", {
              class: _normalizeClass([activeMenu.value === item.value ? 'icon-color-white' : 'color-585D71', 'icon-size-medium', item.icon])
            }, null, 2),
            _createElementVNode("span", _hoisted_3, _toDisplayString(item.name), 1)
          ], 10, _hoisted_2)
        }), 64))
      ]),
      _createElementVNode("div", {
        class: "aside-footer",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (handleMenu('history')))
      }, [
        _createVNode(_component_el_avatar, {
          size: 70,
          src: _unref(useInfo).avatar,
          fit: "cover"
        }, null, 8, ["src"])
      ])
    ]),
    _: 1
  }))
}
}

})