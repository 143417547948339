import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import * as directiveObj from "@/directive";
import pinia from "@/store/pinia";
import "@/style/index.scss";
import V3DragZoom from "v3-drag-zoom";
import "v3-drag-zoom/dist/style.css";
const app = createApp(App);
app.use(ElementPlus);
app.use(router);
app.use(pinia);
app.use(V3DragZoom);
Object.keys(directiveObj).forEach((item) => {
  app.directive(item, directiveObj[item as keyof typeof directiveObj]);
});
app.mount("#app");
