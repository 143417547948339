import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import layout from "@/layout/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: layout,
    redirect: "/inspiration",
    children: [
      {
        path: "/inspiration",
        name: "inspiration",
        meta: {
          keepAlive: true,
        },
        component: () => import("@views/inspiration/index.vue"),
      },
      {
        path: "/fitment",
        name: "fitment",
        meta: {
          keepAlive: true,
        },
        component: () => import("@views/fitment/index.vue"),
      },
      {
        path: "/history",
        name: "history",
        component: () => import("@views/history/index.vue"),
      },
      {
        path: "/shopping-cart",
        name: "shopping_cart",
        component: () => import("@views/shopping-cart/index.vue"),
      },
      {
        path: "/submit-order",
        name: "submit_order",
        component: () => import("@views/submit-order/index.vue"),
      },
      {
        path: "/order",
        name: "order",
        component: () => import("@views/order/index.vue"),
      },
      {
        path: "/booking",
        name: "booking",
        component: () => import("@views/booking/index.vue"),
      },
      {
        path: "/details/:id",
        name: "image-details",
        component: () => import("@views/image-details/index.vue"),
      },
      {
        path: "/edit/:id",
        name: "image-edit",
        component: () => import("@views/image-edit/index.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
