<template>
  <el-main class="h-100 w-100">
    <router-view v-slot="{ Component, route }">
      <keep-alive>
        <component :is="Component" :key="route.name" v-if="route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" :key="route.name" v-if="!route.meta.keepAlive" />
    </router-view>
  </el-main>
</template>
<script setup></script>
<style lang="scss" scoped>
.el-main {
  width: var(--el-main-width);
  background-color: var(--el-main-bg);
}
</style>
