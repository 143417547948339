import { createApp, Directive } from "vue";
import Loading from "./index.vue";

export const loadingCircle: Directive = {
  mounted(el, binding) {
    const app = createApp(Loading);
    const instance = app.mount(document.createElement("div"));
    el.instance = instance;
    if (binding.value) {
      appendEl(el);
    }
  },
  updated(el, binding) {
    if (binding.value !== binding.oldValue) {
      binding.value ? appendEl(el) : removeEl(el);
    }
  },
};
// 插入元素
const appendEl = (el: any) => {
  el.style.position = "relative";
  el.style.display = "flex";
  el?.appendChild(el.instance.$el);
};
// 移除元素
const removeEl = (el: any) => {
  el.style.position = "";
  const $el = el.instance.$el;
  if (el?.contains($el)) {
    el?.removeChild($el);
  }
};
