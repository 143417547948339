<template>
  <el-config-provider :locale="zhCn">
    <router-view />
  </el-config-provider>
</template>
<script lang="ts" setup>
import { onMounted } from "vue";
import useInfo from "@/store/module/useInfo";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import useHistoryImages from "@/store/module/useHistoryImages";

onMounted(() => {
  const { getData: getUseInfo } = useInfo();
  const { getData: getHistoryImages } = useHistoryImages();
  getUseInfo();
  getHistoryImages();
});
</script>
<style lang="scss">
body,
html,
#app {
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
