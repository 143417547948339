import { defineStore } from "pinia";
import use from "@/data/use.json";

export default defineStore("useInfo", {
  state: () => {
    return {
      useInfo: {
        avatar: "",
        name: "",
      },
    };
  },
  actions: {
    async getData() {
      this.useInfo = use.data;
    },
  },
});
